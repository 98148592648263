import React from 'react';
import { Grid, Typography, Button, DialogContent, Dialog } from '@material-ui/core';
import SignaturePad from 'signature_pad';

export default class SignaturePadContainer extends React.Component {
  state = {
    open: this.props.open,
  };

  constructor(props) {
    super(props)
    this._isWindows = navigator.appVersion.indexOf("Win")!=-1 ? true : false;
    this._global = window;
  }

  startSign = (e) => {
    let providerCnv = document.getElementById('providerCnv');
    this.signaturePad = new SignaturePad(providerCnv,{
      backgroundColor:"rgb(255,255,255)", // white background not working
      penColor:"rgb(0,0,0)"
    });

    // Change canvas aspect ratio so the actual drawing and pointer will not misalign
    providerCnv.width = providerCnv.offsetWidth * 1;
    providerCnv.height = providerCnv.offsetHeight * 1;
    providerCnv.getContext("2d").scale(1, 1);

    // Returns signature image as an array of point groups
    //const data = this.signaturePad.toData();
    // const data = this.signaturePad.toDataURL("image/jpeg");
    // const data2 = this.signaturePad.toDataURL("image/svg+xml");
    // Draws signature image from an array of point groups
    //signaturePad.fromData(data);

    // Clears the canvas
    this.signaturePad.clear();
    
    // Returns true if canvas is empty, otherwise returns false
    //signaturePad.isEmpty();
    // Unbinds all event handlers
    //signaturePad.off();
    // Rebinds all event handlers
    this.signaturePad.on();
  }

  startProviderSign = (e) => {
    //e.preventDefault();
    //var tmr;
    var providerCtx = document.getElementById('providerCnv').getContext('2d');
    SetDisplayXSize( 500 );
    SetDisplayYSize( 100 );
    SetTabletState(0, this.tmr);
    SetJustifyMode(0);
    ClearTablet();
    if(this.tmr == null){
      this.tmr = SetTabletState(1, providerCtx, 50);
    }
    else{
      SetTabletState(0, this.tmr);
      this.tmr = null;
      this.tmr = SetTabletState(1, providerCtx, 50);
    }
  }

  clearSign = (e) => {
    if (this._isWindows) {
      let ctx = document.getElementById('providerCnv').getContext('2d');
      ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
      ClearTablet();
    }
    else {
      this.signaturePad.clear();
    }
  }

  saveSign = (e) => {
    const { target_signature_ref } = this.props;
    let data = null;
    if (this._isWindows) {
      let canvas = document.getElementById('providerCnv');
      data = canvas.toDataURL();
      //clearInterval(this.tmr);
      SetTabletState(0, this.tmr);
    }
    else {
      data = this.signaturePad.toDataURL();
      // const data1 = this.signaturePad.toDataURL("image/jpeg");
      // const data2 = this.signaturePad.toDataURL("image/svg+xml");
    }
    target_signature_ref.src = data;
    this.props.close();
  }

  onDialogUpdate = () => {
    if (this.props.open){
      if (this._isWindows) {
        this.startProviderSign();
      }
      else {
        this.startSign();
      }
    }
  }

  render() {
    return (
      <Dialog
          open={this.props.open}
          onClose={this.props.close}
          onEntered={this.onDialogUpdate}
          aria-labelledby="form-dialog-title"
          >
        <DialogContent>
          <Typography variant="h5">Enter Signature</Typography>
          <Typography variant='body2' style={{ color: '#ff5100', marginTop: '10px' }}>Draw your signature below, then click Save & Use to continue</Typography>
          <canvas id="providerCnv" name="providerCnv" 
            style={{border:'1px solid #eeeeee',width:'100%',height:'200px',marginTop:'10px'}}>
          </canvas>
          <Grid container justify='center'>
            <Grid item xs={6} style={{ textAlign: 'left', marginTop: '10px' }}>
              <Button className='milo-btn-white' variant='contained' style={{ width: '100%' }} onClick={this.clearSign}>
                Clear
              </Button>
            </Grid>
            <Grid item xs={6} style={{ textAlign: 'right', marginTop: '10px' }}>
              <Button className='milo-btn-orange' variant='contained' style={{ width: '100%' }} onClick={this.saveSign}>
                Save & Use
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
		);
  }
}
